import React from "react";
import Logo from '../utils/images/3.png'
import { MainNavbarItems } from "../utils/Data";
import { NavLink } from "react-router-dom";

function Fotter () {
    return (
        <div>
            <section className="px-5 lg:px-40 mt-24 z-50 relative">

                <section className="bg-[#A02B2D] px-5  lg:px-10 py-14 rounded-3xl">
                    <div className="text-center space-y-10 text-white">
                        <h1 className="text-3xl lg:text-7xl font-bold">Ready to Partner with Us?</h1>
                        <p className="lg:text-3xl font-semibold">Let’s Build the Future Together</p>
                        <div className=" gap-5 justify-center hidden lg:flex">
                            <p>
                                <input type="email" name="email" placeholder="Enter your email" className="w-[300px] rounded-full outline-none px-5 text-black py-4" id="" />
                            </p>
                            <button className="px-8 py-4 bg-black rounded-full text-lg font-semibold">Get started</button>

                        </div>

                        <div className="flex bg-white py-2 px-2 rounded-full lg:hidden">
                        <input type="email" name="" id=""  placeholder="Enter your email" className="bg-transparent text-black w-[55%] outline-none flex-grow" />              <button className="px-3 py-2 h-[60px] font-bold text-sm rounded-full bg-[#000000]">Get started</button>

                    </div>
                    </div>
                </section>

            </section>


            <section className="bg-black transform z-10 -mt-10">

                <section className=" px-5 lg:px-28 py-10">

                    <section className="flex justify-between mt-20 gap-2 lg:gap-0 flex-wrap lg:flex-nowrap">

                        <div>
                            <img src={Logo} alt="" />
                        </div>

                        <div className="lg:text-start text-right mt-10 lg:mt-0">
                            <h1 className="text-white font-bold text-2xl">Company</h1>
                        <ul className='space-y-5 text-white mt-5'>
                            {MainNavbarItems.map(item => (
                                <li key={item.id}>
                                <NavLink
                                    to={item.link}
                                    className={({ isActive }) =>
                                    isActive ? " uppercase  font-bold" : "uppercase font-normal"
                                    }
                                >
                                    {item.name}
                                </NavLink>
                                </li>
                            ))}
                            </ul>
                        </div>

                        <div className="text-start mt-10 lg:mt-0">
                            <h1 className="text-white font-bold text-2xl">Help</h1>
                            <ul className='space-y-5 text-white mt-5'> 
                                <li>Contact us</li>
                                <li>Our Policies</li>
                                <li>Accounts</li>
                                <li>Terms and conditions</li>
                            </ul>
                        </div>

                        <div className="text-start mt-10 lg:mt-0">
                            <h1 className="text-white font-bold text-2xl">Social</h1>
                            <ul className='space-y-5 text-white mt-5'> 
                                <li>Facebook</li>
                                <li>Instagram</li>
                                <li>Twitter</li>
                                <li>Linkeldn</li>
                            </ul>
                        </div>
                    </section>

                </section>

                <section className="flex justify-between flex-wrap lg:flex-nowrap gap-5 lg:gap-0 lg:text-2xl border-t py-10 lg:px-28 text-white mt-10">
                    <div>
                        <p>© 2024 VeeKITES Move All rights reserved</p>
                    </div>

                    <div>
                        <p>Terms & conditions | Sitemap | Privacy Policy | Cookies Policy</p>
                    </div>

                </section>

            </section>

        </div>
    )
}

export default Fotter;