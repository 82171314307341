import React from 'react'
import Hero from '../utils/images/aboutus.png'
import Pic1 from '../utils/images/project1.png'
import Locate from '../utils/images/akar-icons_location.png'
import Pro1 from '../utils/images/progal1.png'
import Pro2 from '../utils/images/progal2.png'
import Pro3 from '../utils/images/progal3.png'
import Pro4 from '../utils/images/progal4.png'
import Pro5 from '../utils/images/progal5.png'
import { FaCheckCircle } from 'react-icons/fa'


function Projectes() {
  return (
    <div>
           <section className='relative'>
           <div>
                <img src={Hero} alt="hero" className='w-full object-cover h-[50vh] lg:h-screen xl:h-full' />
            </div>

            <div className='space-y-5 lg:space-y-10 absolute top-32 px-2 lg:px-28 text-center text-white'>
              <h1 className='text-3xl lg:text-7xl font-bold'>Showcasing Excellence in Every Project</h1>
              <p className='lg:text-2xl'>Delivering excellence across civil engineering, oil and gas, and global project management. Our expertise drives innovation, fuels progress, and builds a sustainable future.</p>
            </div>
        </section>
        

        <section className='px-5 lg:px-14 xl:px-32 mt-24'>
            <div>
                <h1 className='text-[#A02B2D] font-bold text-[32px] lg:text-5xl'>Landmark Bridge Construction</h1>
                <p className='xl:w-[1048px] text-lg leading-[35px] mt-5'>A state-of-the-art suspension bridge connecting communities and facilitating trade. Completed on time and within budget, it stands as a testament to our engineering prowess.</p>
            </div>


            <section className='flex justify-between mt-14 flex-wrap xl:flex-nowrap'>
            <div>
              <p>
                <img src={Pic1} alt=""  
                className='w-full xl:w-[1052px] lg:h-[620px] rounded-lg'
                />
              </p>
            </div>

            <section className='space-y-5 bg-[#FFE1E2] rounded-xl mt-14 xl:mt-0'>
            <div className='px-10 py-8 xl:w-[412px] space-x-5 flex text-[#000000] rounded-xl'>
                <p> <img src={Locate} alt="" /> </p>
                <div className='space-y-2'>
                <p className='text-base'>Call support Center 24/7 </p>
                <div>
                <p className='font-bold text-lg'>Lagos, Nigeria</p>
                </div>  
                </div>
               
            </div>
            <div className='px-10 py-8 xl:w-[412px] space-x-5 flex text-[#000000] rounded-xl'>
                <p> <img src={Locate} alt="" /> </p>
                <div className='space-y-2'>
                <p className='text-base'>Sector</p>
                <div>
                <p className='font-bold text-lg'>Civil & Constructions</p>
                </div>  
                </div>
               
            </div>
            <div className='px-10 py-8 xl:w-[412px] space-x-5 flex text-[#000000] rounded-xl'>
                <p> <img src={Locate} alt="" /> </p>
                <div className='space-y-2'>
                <p className='text-base'>Scope of work</p>
                <div>
                <p className='font-bold text-lg'>+1200</p>
                </div>  
                </div>
               
            </div>
            <div className='px-10 py-8 xl:w-[412px] space-x-5 flex text-[#000000] rounded-xl'>
                <p> <img src={Locate} alt="" /> </p>
                <div className='space-y-2'>
                <p className='text-base'>Completion Date</p>
                <div>
                <p className='font-bold text-lg'>1 Jan 2026</p>
                </div>  
                </div>
               
            </div>

        </section>

           </section>

           <section className='mt-14 xl:w-[1052px]'> 
          <div>
            <p className='leading-[35px] text-lg'>This project involved the comprehensive redevelopment of urban infrastructure in [City], focusing on improving transportation efficiency, public safety, and environmental sustainability. The project scope included the redesign and reconstruction of roads, bridges, and public spaces to better serve the community’s needs and accommodate future growth.</p>
          </div>
        </section>

        <section className='mt-14'>
          <h1 className='text-[#A02B2D] text-[40px] font-bold'>Project Objectives</h1>
          <div className='space-y-8 mt-10'>
            <p className='flex gap-5 text-lg font-medium'> <FaCheckCircle className='text-5xl lg:text-2xl '/>  Enhance the transportation network to reduce traffic congestion and improve safety.</p>
            <p className='flex gap-5 text-lg font-medium'> <FaCheckCircle className='text-5xl lg:text-2xl '/>  Upgrade existing infrastructure to meet modern standards for durability and sustainability.</p>
            <p className='flex gap-5 text-lg font-medium'> <FaCheckCircle className='text-5xl lg:text-2xl '/>  Incorporate environmentally friendly practices to minimize the project's ecological footprint.</p>
            <p className='flex gap-5 text-lg font-medium'> <FaCheckCircle className='text-5xl lg:text-2xl '/> Improve public spaces to foster a sense of community and increase usability.</p>

          </div>
        </section>

        <section className='mt-14'>
        <h1 className='text-[#A02B2D] text-[40px] font-bold'>Project Gallery</h1>
        <div className='flex space-x-5 lg:space-y-0 space-y-2 justify-center mt-10 flex-wrap lg:flex-nowrap'>
          <p>
            <img src={Pro1} alt=""  />
          </p>
          <p>
          <img src={Pro2} alt=""  />
          </p>
          <p>
          <img src={Pro3} alt=""  />
          </p>
          <p>
            <img src={Pro4} alt=""  />
          </p>
          <p>
            <img src={Pro5} alt=""  />
          </p>

        </div>



        </section>

     


        </section>
      
    </div>
  )
}

export default Projectes
