import React, { useEffect } from 'react'
import Hero from '../utils/images/aboutus.png'
import abou1 from '../utils/images/commited1.png'
import abou2 from '../utils/images/commited2.png'
import Vision from '../utils/images/vision.png'
import History from '../utils/images/history.png'
import Map from '../utils/images/map.png'
import { Teams, Others } from '../utils/Data'
import Mission1 from '../utils/images/mission.png'
import Mission2 from '../utils/images/mission2.png'
import Mission3 from '../utils/images/mission3.png'


function About() {
  useEffect(() => {
    window.scrollTo(0, 0)
})

  return (
    <div>

        <section className='relative'>
            <div>
                <img src={Hero} alt="hero" className='w-full object-cover h-[50vh] lg:h-full' />
                <div className="absolute inset-0 bg-black opacity-50 lg:hidden"></div>
            </div>

            <div className='space-y-5 lg:space-y-10 absolute top-32 lg:top-14 xl:top-32 px-2 lg:px-28 text-center text-white'>
              <h1  className='text-3xl lg:text-5xl xl:text-7xl font-bold'>Shaping the Future with Expertise and Innovation</h1>
              <p className='lg:text-2xl'>Delivering excellence across civil engineering, oil and gas, and global project management. Our expertise drives innovation, fuels progress, and builds a sustainable future.</p>
            </div>
        </section>



        <section className="px-3 lg:px-14 xl:px-28 gap-10 xl:gap-0 mt-14 lg:mt-24 flex justify-between flex-wrap xl:flex-nowrap">
                <div className="space-y-5 mt-5">
                    <h1 className="xl:w-[720px] text-[27px] lg:text-[55px] text-[#A02B2D] font-bold">Committed to Innovation, Quality,& Global Impact</h1>
                    <p className="xl:w-[724px] text-lg lg:text-2xl leading-10 lg:leading-[55px] font-medium">At, VeeKITES , we specialise in delivering cutting-edge solutions across multiple industries, including civil engineering, oil and gas, project management, and general contracts. Our global presence and dedicated team have enabled us to build a reputation for excellence and innovation. We are committed to turning challenges into opportunities, ensuring that every project we undertake leaves a lasting, positive impact.</p>
                </div>

                <div className='mt-14 xl:mt-0 ml-[15%] xl:ml-0'> 
                        <p className='transform -translate-x-14'>
                            <img src={abou1} alt="" className='w-[90%] lg:w-full' />
                        </p>
                <p className='mt-5'>
                    <img src={abou2} alt="" className='w-[90%] lg:w-full'  />
                </p>
                    </div>

            </section>


            <section className='bg-black rounded-t-3xl mt-32 text-white'>

              <section className='flex flex-wrap-reverse xl:flex-nowrap justify-between px-5 lg:px-14 xl:px-28 py-20'>

                <div className='flex gap-5 mt-14 xl:mt-0'>
                  <p>
                    <img src={Mission1} alt="" />
                  </p>
                  <p>
                    <img src={Mission2} alt="" />
                  </p>
                  <p>
                    <img src={Mission3} alt="" />
                  </p>
                </div>

                <div className='space-y-5 mt-5'>
                  <h1 className='text-[32px] lg:text-[55px] font-semibold'>Mission Of VeeKITES</h1>
                  <p className='xl:w-[768px] text-lg lg:text-2xl leading-9 lg:leading-[55px]'>Our mission is to be the most foremost Architectural Design , Civil, Mechanical,electric, Building engineering, general Supply as well as Technical company of the milleniueum</p>
                </div>

              </section>

            </section>


            <section className='bg-[#FFE1E2]'>
                
                <section className='px-3 lg:px-14 xl:px-28 py-32 flex justify-between flex-wrap lg:flex-nowrap'>
                    <div className='lg:w-[768px] space-y-5 xl:space-y-14'>
                        <h1 className='text-[27px] lg:text-[30px] xl:text-[50px] text-[#A02B2D] font-bold'>Vision Of VeeKITES</h1>
                        <p className="lg:w-[450px] xl:w-[724px] text-lg xl:text-2xl leading-10 xl:leading-[55px] font-medium">Our mission is to highlight and slove crucial technical issues in Engineering Constructions and supply industry to the satisfactory of our valued Clients</p>
                    </div>

                    <div className='mt-14 lg:mt-0'>
                        <img src={Vision} alt="vision" />
                    </div>
                </section>

            </section>



            <section className='px-3 lg:px-14 xl:px-28 mt-24 flex justify-between flex-wrap gap-10 lg:flex-nowrap'>        
                    <div className='hidden lg:block'>
                        <img src={History} alt="vision" className='w-[100%]' />
                    </div>

                    <div className='w-[800px] space-y-2'>
                        <h1 className='text-[27px] lg:text-[30px] xl:text-[50px] text-[#A02B2D] font-bold'>Our History</h1>
                        <p className=" lg:w-[724px] text-lg xl:text-2xl leading-10 xl:leading-[55px] font-medium">Incorporated 11th june 2013, VeeKITES started as a small firm with a vision to revolutionize the global services industry. Over the years, we have expanded our expertise, embracing new challenges and growing into a leading provider of comprehensive solutions in civil engineering, oil and gas, and project management. Our journey has been marked by a series of significant milestones, each contributing to our legacy of excellence and innovation.</p>
                    </div>
                </section>



                <section className="relative mt-[120%] lg:mt-80">
  {/* Image with Black Overlay */}
  <div>
    
    <div className='relative'>
        <div className="absolute inset-x-0 -top-[50%] lg:-top-[30%] h-[150%] lg:h-[130%] bg-black opacity-95 rounded-3xl"></div>
    <img src={Map} alt="" className="w-full h-[629px] object-cover" />
    </div>
    
    
    {/* Text on Top of Image */}
    <div className="absolute inset-x-0 flex -top-64 lg:-top-20  flex-col justify-center px-5 lg:px-28 items-center text-white space-y-5 lg:space-y-10">
      <h1 className=" text-[32px] lg:text-[40px] xl:text-[50px] font-semibold">What sets VeeKITES apart</h1>
      <p className="text-lg leading-9 xl:text-2xl xl:leading-[55px]">
        What distinguishes VeeTIKES is our holistic approach to solving complex challenges. We bring together a diverse team of experts from various disciplines, ensuring that we approach each project with fresh perspectives and tailored solutions. Our commitment to sustainability, combined with our innovative technologies, allows us to deliver projects that not only meet today’s needs but also anticipate tomorrow’s challenges. We’re more than just a service provider—we’re a partner dedicated to your success.
      </p>
    </div>

    {/* Stats on Top of Image */}
    <section className="absolute bottom-24 xl:bottom-32 inset-x-0 flex justify-around text-2xl text-white">
      <div className="space-y-3 text-center ">
        <h1 className="text-[32px] lg:text-6xl xl:text-9xl text-[#A02B2D] font-bold">4.5k+</h1>
        <p className='text-base lg:text-lg'> Clients across the world</p>
      </div>
      <div className="space-y-3 text-center">
        <h1 className="text-[32px] lg:text-6xl xl:text-9xl text-[#A02B2D] font-bold">1.5k+</h1>
        <p className='text-base lg:text-lg'>Completed Projects</p>
      </div>
      <div className="space-y-3 text-center">
        <h1 className="text-[32px] lg:text-6xl xl:text-9xl text-[#A02B2D] font-bold">100+</h1>
        <p className='text-base lg:text-lg'>Reached countries</p>
      </div>
    </section>
  </div>
                </section>


                <section className='px-3 lg:px-14 xl:px-28 mt-24'>
                   
                <div className='text-center space-y-2'>
                        <h1 className='text-[27px] lg:text-[40px] xl:text-[50px] text-[#A02B2D] font-bold'>Our Team</h1>
                        <p className="text-lg leading-9 lg:text-2xl lg:leading-[55px] font-medium">Our team is our greatest asset. Comprising seasoned professionals and dynamic innovators, our experts bring a wealth of knowledge and a passion for excellence to every project. We believe in fostering a collaborative environment where creativity and expertise thrive, ensuring that our team is always equipped to deliver top-tier solutions. Meet the people who make it all happen and drive our success.</p>
                    </div>



                    <section className='flex flex-wrap justify-between gap-5 lg:gap-5 xl:gap-0'>
                      {Teams.map((team) => (
                        <div className='lg:w-[23%] space-y-3  text-lg mt-16 flex-grow'>
                      <p>
                            <img src={team.img} alt="" className='w-full object-cover h-[310px] flex-grow' />
                          </p>
                          <h1 className='font-semibold text-xl'>{team.name}</h1>
                          <p>{team.position}</p>
                        </div>

                      ))}

                    </section>
                </section>


          <section className='bg-[#FFE1E2] mt-24 hidden lg:block'>
          <section className="px-14 xl:px-28 mt-24 py-20">
            <div className="text-center">
                <h1 className="text-[55px] font-semibold">What others are saying about Us</h1>
            </div>


            <section className="flex justify-between mt-14">
                {Others.map((say) => (
                    <div className="space-y-5 w-[500px]">
                        <p>
                            <img src={say.img} alt="" />
                        </p>
                        <h1 className="text-3xl font-medium">{say.title}</h1>
                        <p className="leading-10 text-xl"> {say.summ}</p>
                        <p className="text-lg">{say.pro}</p>

                    </div>
                ))}
            </section>
           </section>

          </section>


          <section className='bg-[#FFE1E2] mt-24 lg:hidden'>
          <section className="px-3 mt-24 py-20">
            <div className="text-center">
                <h1 className="text-[27px] font-semibold">What others are saying about Us</h1>
            </div>


            <section className="flex  mt-14">
                {Others.slice(0, 1).map((say) => (
                    <div className="space-y-5 w-[500px] flex flex-col justify-center items-center flex-grow">
                        <p>
                            <img src={say.img} alt="" />
                        </p>
                        <h1 className="text-2xl font-medium">{say.title}</h1>
                        <p className="leading-10 text-xl"> {say.summ}</p>
                        <p className="text-lg">{say.pro}</p>

                    </div>
                ))}
            </section>
           </section>

          </section>

             


  

 


      
    </div>
  )
}

export default About
