import Project1 from './images/project1.png'
import Project2 from './images/project2.png'
import Project3 from './images/project3.png'
import Profile from './images/profile.png'
import Goggle from './images/gogle.png'
import Lotte from './images/lotte logo.png'
import Naver from './images/never.png'
import Paypal from './images/paypal logo 1.png'
import Bosch from './images/bosch logo 1.png'
import Tech from './images/tech crunch 1.png'
import New1 from './images/new1.png'
import Pro1 from './images/newpro1.png'
import Team1 from './images/team1.png'
import Team2 from './images/team2.png'
import Team3 from './images/team3.png'
import Team4 from './images/team4.png'
import News2 from './images/2News.png'
import News3 from './images/News3.png'
import Blog2 from './images/2blog.png'
import Blog4 from './images/blog4.png'
import Blog5 from './images/blog5.png'
import Blog6 from './images/blog6.png'
import Blog7 from './images/blog7.png'
import Blog8 from './images/blog8.png'
import Blog9 from './images/blog9.png'

interface Team {
    id: number,
    img: string,
    name: string,
    position: string
}

export const Teams: Team[] = [
    {
        id: 0,
        img: Team1,
        name: "Jocelyn Schleifer",
        position: "Managing Director"
    },
    {
         id: 1,
        img: Team2,
        name: "Jocelyn Schleifer",
        position: "Managing Director"
    },
    {
        id: 2,
       img: Team3,
       name: "Jocelyn Schleifer",
       position: "Managing Director"
   },
   {
    id: 3,
   img: Team4,
   name: "Jocelyn Schleifer",
   position: "Managing Director"
    },
    {
        id: 4,
        img: Team1,
        name: "Jocelyn Schleifer",
        position: "Managing Director"
    },
    {
        id: 5,
        img: Team2,
        name: "Jocelyn Schleifer",
        position: "Managing Director"
    },
    {
        id: 6,
        img: Team3,
        name: "Jocelyn Schleifer",
        position: "Managing Director"
    },
    {
        id: 7,
        img: Team4,
        name: "Jocelyn Schleifer",
        position: "Managing Director"
    }
]

interface Navbar {
    id: number,
    name: string,
    link: string
}

export const MainNavbarItems: Navbar[] = [
    { id: 1, name: 'Home', link: '/' },
    { id: 2, name: 'About', link: '/about' },
    { id: 4, name: 'Projects', link: '/project' },
    { id: 3, name: 'News and Insight', link: '/news' },
    { id: 6, name: 'Contact ', link: '/contact' },
    { id: 7, name: 'Careers ', link: '/career' },
    { id:8, name:'Services', link:'/service' }
];

interface Project {
    id: number,
    title: string,
    summ: string,
    img: string
}

export const Projects: Project[] = [
    {
        id: 1,
        title: "Landmark Bridge Construction",
        summ: "A state-of-the-art suspension bridge connecting communities and facilitating trade. Completed on time and within budget, it stands as a testament to our engineering prowess.",
        img: Project1
    },
    {
        id: 2,
        title: "Offshore Oil Platform Development",
        summ: "A complex offshore oil platform designed for efficient extraction and environmental safety. Our innovative approach ensured minimal impact on marine life while maximizing output",
        img: Project2
    },
    {
        id: 3,
        title: "National Infrastructure Upgrade",
        summ: "We led the upgrade of national infrastructure, improving transportation networks and utilities across the country. Our project management expertise ensured a seamless integration of new systems with existing structures.",
        img: Project3
    },
    {
        id: 4,
        title: "Landmark Bridge Construction",
        summ: "A state-of-the-art suspension bridge connecting communities and facilitating trade. Completed on time and within budget, it stands as a testament to our engineering prowess.",
        img: Project2
    },
    {
        id: 5,
        title: "Landmark Bridge Construction",
        summ: "A state-of-the-art suspension bridge connecting communities and facilitating trade. Completed on time and within budget, it stands as a testament to our engineering prowess.",
        img: Project1
    },
    {
        id: 6,
        title: "Landmark Bridge Construction",
        summ: "A state-of-the-art suspension bridge connecting communities and facilitating trade. Completed on time and within budget, it stands as a testament to our engineering prowess.",
        img: Project3
    },

]

interface Other {
    id: number,
    title: string,
    summ: string,
    img: string,
    pro: string
}

export const Others: Other[] = [
    {
        id: 1,
        title: "Energy Solutions LLC",
        summ: "“Working with VEEKITES was a game-changer for our project. Their expertise in civil engineering and project management ensured we stayed on schedule and within budget. We couldn't have done it without them”",
        img: Profile,
        pro: "Review from Thompson, Project Manager"
    },
    {
        id: 2,
        title: "Energy Solutions LLC",
        summ: "“Working with VEEKITES was a game-changer for our project. Their expertise in civil engineering and project management ensured we stayed on schedule and within budget. We couldn't have done it without them”",
        img: Profile,
        pro: "Review from Thompson, Project Manager"
    },
    {
        id: 3,
        title: "Energy Solutions LLC",
        summ: "“Working with VEEKITES was a game-changer for our project. Their expertise in civil engineering and project management ensured we stayed on schedule and within budget. We couldn't have done it without them”",
        img: Profile,
        pro: "Review from Thompson, Project Manager"
    },
    
]

interface Brand {
    id: number,
    img: string
}

export const Brands: Brand[] = [
    {
        id: 1,
        img: Goggle
    },
    {
        id: 2,
        img: Lotte
    },
    {
        id: 3,
        img: Naver
    },
    {
        id: 4,
        img: Paypal
    },
    {
        id: 5,
        img: Bosch
    },
    {
        id: 6,
        img: Tech
    },
]

interface FAQ {
    id: number,
    title: string,
    summ: string
}
export const FAQs: FAQ[] = [
    {
        id: 1,
        title: "How does it work",
        summ: "Lorem ipsum yuas yua uyfa uya yuta iutga iuta"
    },
    {
        id: 2,
        title: "Is my data course?",
        summ: "Lorem ipsum yuas yua uyfa uya yuta iutga iuta"
    },
    {
        id: 3,
        title: "Does VeeKITES work well large team?",
        summ: "Lorem ipsum yuas yua uyfa uya yuta iutga iuta"
    },
    {
        id: 4,
        title: "How do i get to reach us",
        summ: "Lorem ipsum yuas yua uyfa uya yuta iutga iuta"
    }
]

interface New {
    id: number,
    img: string,
    proImg: string,
    title: string,
    detail: string,
    name: string,
    category: string,
} 


export const Blogs: New[] = [
    {
        id: 1,
        img: New1,
        proImg: Pro1,
        title: "The Impact of Technology on the Workplace: How Technology is Changing",
        detail: "August 20, 2022",
        name: "Tracey Wilson",
        category: "Technology"
    },
    {
        id: 2,
        img: Blog2,
        proImg: Pro1,
        title: "The Impact of Technology on the Workplace: How Technology is Changing",
        detail: "August 20, 2022",
        name: "Jason Francisco",
        category: "Technology"
    },
    {
        id: 3,
        img: News3,
        proImg: Pro1,
        title: "The Impact of Technology on the Workplace: How Technology is Changing",
        detail: "August 20, 2022",
        name: "Elizabeth Slavin",
        category: "Technology"
    },
    {
        id: 4,
        img: Blog4,
        proImg: Pro1,
        title: "The Impact of Technology on the Workplace: How Technology is Changing",
        detail: "August 20, 2022",
        name: "Tracey Wilson",
        category: "Technology"
    },
    {
        id: 5,
        img: Blog5,
        proImg: Pro1,
        title: "The Impact of Technology on the Workplace: How Technology is Changing",
        detail: "August 20, 2022",
        name: "Jason Francisco",
        category: "Technology"
    },
    {
        id: 6,
        img: Blog6,
        proImg: Pro1,
        title: "The Impact of Technology on the Workplace: How Technology is Changing",
        detail: "August 20, 2022",
        name: "Elizabeth Slavin",
        category: "Technology"
    },
    {
        id: 7,
        img: Blog7,
        proImg: Pro1,
        title: "The Impact of Technology on the Workplace: How Technology is Changing",
        detail: "August 20, 2022",
        name: "Elizabeth Slavin",
        category: "Technology"
    },
    {
        id: 8,
        img: Blog8,
        proImg: Pro1,
        title: "The Impact of Technology on the Workplace: How Technology is Changing",
        detail: "August 20, 2022",
        name: "Elizabeth Slavin",
        category: "Technology"
    },
    {
        id: 9,
        img: Blog9,
        proImg: Pro1,
        title: "The Impact of Technology on the Workplace: How Technology is Changing",
        detail: "August 20, 2022",
        name: "Elizabeth Slavin",
        category: "Technology"
    },
    
]

export const News: New[] = [
    {
        id: 1,
        img: New1,
        proImg: Pro1,
        title: "The Impact of Technology on the Workplace: How Technology is Changing",
        detail: "August 20, 2022",
        name: "Tracey Wilson",
        category: "Technology"
    },
    {
        id: 2,
        img: News2,
        proImg: Pro1,
        title: "The Impact of Technology on the Workplace: How Technology is Changing",
        detail: "August 20, 2022",
        name: "Jason Francisco",
        category: "Technology"
    },
    {
        id: 3,
        img: News3,
        proImg: Pro1,
        title: "The Impact of Technology on the Workplace: How Technology is Changing",
        detail: "August 20, 2022",
        name: "Elizabeth Slavin",
        category: "Technology"
    }
]

interface work {
    id: number,
    title: string,
    summ: string
}

export const WorkWithUs: work[] = [
    {
        id: 0,
        title: "Innovative Environment",
        summ: "We encourage creativity and innovation, empowering our team to explore new ideas and solutions."
    },
    {
        id: 1,
        title: "Growth Opportunities",
        summ: "From continuous learning to career advancement, we invest in our people’s growth."
    },
    {
        id: 2,
        title: "Diverse Team ",
        summ: "Our inclusive culture values diverse perspectives, fostering a collaborative and supportive workplace."
    },
    {
        id: 3,
        title: "Impactful Work",
        summ: "Join a company where your work makes a difference. We tackle challenging projects that drive positive change."
    }
]

interface position {
    id: number,
    position: string,
    type: string,
    location: string,
    description: string
}

export const Positions: position[] = [
    {
        id: 0,
        position: "Civil Engineering",
        type: "Full Time",
        location: "Delta State, Nigeria",
        description: "We are seeking a skilled and motivated Civil Engineer to join our dynamic team. In this role, you will be responsible for designing, planning, and overseeing construction projects that range from roadways and bridges to large-scale infrastructure developments."
    },
    {
        id: 1,
        position: "Civil Engineering",
        type: "Full Time",
        location: "Delta State, Nigeria",
        description: "We are seeking a skilled and motivated Civil Engineer to join our dynamic team. In this role, you will be responsible for designing, planning, and overseeing construction projects that range from roadways and bridges to large-scale infrastructure developments."
    },
    {
        id: 2,
        position: "Civil Engineering",
        type: "Full Time",
        location: "Delta State, Nigeria",
        description: "We are seeking a skilled and motivated Civil Engineer to join our dynamic team. In this role, you will be responsible for designing, planning, and overseeing construction projects that range from roadways and bridges to large-scale infrastructure developments."
    },
    {
        id: 3,
        position: "Civil Engineering",
        type: "Full Time",
        location: "Delta State, Nigeria",
        description: "We are seeking a skilled and motivated Civil Engineer to join our dynamic team. In this role, you will be responsible for designing, planning, and overseeing construction projects that range from roadways and bridges to large-scale infrastructure developments."
    },
    {
        id: 4,
        position: "Civil Engineering",
        type: "Full Time",
        location: "Delta State, Nigeria",
        description: "We are seeking a skilled and motivated Civil Engineer to join our dynamic team. In this role, you will be responsible for designing, planning, and overseeing construction projects that range from roadways and bridges to large-scale infrastructure developments."
    },
    {
        id: 5,
        position: "Civil Engineering",
        type: "Full Time",
        location: "Delta State, Nigeria",
        description: "We are seeking a skilled and motivated Civil Engineer to join our dynamic team. In this role, you will be responsible for designing, planning, and overseeing construction projects that range from roadways and bridges to large-scale infrastructure developments."
    }
]

