import React from "react";
import { MainNavbarItems } from "../utils/Data";
import { NavLink } from "react-router-dom";
import Logo from '../utils/images/3.png'
import { FaLocationArrow , FaPhone, FaMailBulk } from 'react-icons/fa'


function Navbar() {
    return (
        <div className="hidden lg:block">

            <nav className="bg-black flex justify-end items-center space-x-5 text-white py-3 px-20">
           
            <div className="flex space-x-2">
                    <FaPhone className="mt-1"/>
                    <p>Tel: +23490123456789</p>
                </div>
                <div className="flex space-x-2">
                    <FaMailBulk  className="mt-1"/>
                    <p>info@VeeKITES.com</p>
                </div>
                <div className="flex space-x-2">
                    <FaLocationArrow  className="mt-1"/>
                    <p>Delta, Nigeria</p>
                </div>
            </nav>

            <nav className="flex justify-between px-10 xl:px-20 py-5">

            <div>
                <img src={Logo} alt="hjvj" />
            </div>

            <ul className='flex justify-between text-xl lg:text-xs xl:text-base lg:space-x-3  xl:space-x-8 text-[#222222] xl:px-24 py-5'>
          {MainNavbarItems.map(item => (
            <li key={item.id}>
              <NavLink
                to={item.link}
                className={({ isActive }) =>
                  isActive ? " uppercase  font-bold border-b-4 border-[#A02B2D] pb-2" : "uppercase font-normal"
                }
              >
                {item.name}
              </NavLink>
            </li>
          ))}
        </ul>

        <div className="self-start ">
            <button className="border rounded-full bg-[#A02B2D] px-10 py-3 text-white lg:w-[200px] xl:w-[220px] uppercase font-bold lg:text-base xl:text-xl">Request a Consultaion</button>
        </div>

        </nav>


        </div>
    )
}

export default Navbar;