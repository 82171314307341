import React, { useState, useEffect } from 'react';
import Civil1 from '../utils/images/pro1.png';
import Civil2 from '../utils/images/pro12.png';
import Urban1 from '../utils/images/urban.png';
import Urban2 from '../utils/images/urban2.png'

type GalleryProps = {};

function Gallery(props: GalleryProps) {
  const [activeLink, setActiveLink] = useState<number>(0);
  const [images, setImages] = useState<string[]>([]);
  const [videos, setVideos] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const choose: string[] = [
    "Civil Engineering", "Oil & Gas", "Project Management", "Training Services", "General Contracts", "Supplies"
    ];

  useEffect(() => {
    const loadContent = async () => {
      setIsLoading(true);

      if (activeLink === 0) {
        await new Promise(resolve => setTimeout(resolve, 500)); 
      } else {
        // Simulating an async loading of videos
        await new Promise(resolve => setTimeout(resolve, 500)); 
      }

      setIsLoading(false);
    };

    loadContent();
  }, [activeLink]);

  return (
    <div className='lg:px-14 xl:px-28 mt-24'>

<section>
  {/* First Section */}
  <section className='lg:px-10   text-base z-50 relative'>
    <div className='text-center flex flex-wrap lg:flex-nowrap justify-center gap-2 bg-black rounded-full py-3 text-white mt-10'>
      {choose.map((ch, index) => (
        <p key={index} className='self-start'>
          <button
            onClick={() => setActiveLink(index)}
            className={activeLink === index ? 'border px-5 py-2 rounded-full text-black bg-white' : 'text-white px-5 py-2'}
          >
            {ch}
          </button>
        </p>
      ))}
    </div>
  </section>

  {/* Second Section */}
  <section className='flex  flex-wrap  justify-between px-3 lg:px-10 border py-20 lg:h-auto -mt-5 bg-[#FFE1E2] rounded-3xl relative z-10'>
    <div className='space-y-5'>
    <h1 className='text-3xl lg:text-3xl xl:text-4xl font-bold text-[#A02B2D] '>Civil Engineering</h1>
    <p className=" text-lg leading-9 lg:text-2xl lg:leading-[55px] font-medium">Our Civil Engineering services cover a broad spectrum of projects, including infrastructure development, urban planning, and environmental engineering. With a focus on innovation and sustainability, we deliv+er solutions that enhance communities and drive progress.</p>
    </div>

    <div className='flex flex-wrap xl:flex-nowrap justify-between w-[90%] mt-40'>
        <div className='space-y-5'>
        <h1 className='text-3xl lg:text-3xl xl:text-4xl font-bold text-[#A02B2D] '>Infrastructure Development</h1>
    <p className=" text-lg leading-9 lg:text-2xl lg:leading-[55px] xl:w-[800px] font-medium">We specialize in the design and construction of infrastructure that defines the modern landscape. From towering skyscrapers to expansive bridges, our civil engineering expertise ensures projects that are safe, sustainable, and visually striking. We manage every phase, from concept to completion, delivering on time and within budget.</p>
    </div>

    <div className='relative mt-24  xl:mt-0'>
          <img src={Civil1} alt="First" className=' mx-auto -translate-y-20' />
          <img src={Civil2} alt="Second" className=' mx-auto absolute top-0 left-0 translate-x-14 translate-y-14' style={{ zIndex: -1 }} />
        </div>
        </div>


        <div className='flex justify-between flex-wrap-reverse xl:flex-nowrap w-[90%] mt-40'>
            <div className='relative flex flex-row xl:flex-col'>
                <img src={Urban1} alt="First" className=' mx-auto w-[45%] xl:-translate-y-20 xl:w-full' />
                <img src={Urban2} alt="Second" className=' mx-auto  w-[45%] xl:w-full'  />
                </div>

                <div className='space-y-5'>
                <h1 className='text-3xl lg:text-3xl xl:text-4xl font-bold text-[#A02B2D] '>Urban Planning</h1>
            <p className=" text-lg leading-9 lg:text-2xl lg:leading-[55px] lg:w-[750px] font-medium">WAt VeeKITES, our projects are a testament to our commitment to quality, innovation, and client satisfaction. From complex civil engineering challenges to groundbreaking work in the oil and gas industry, our portfolio spans a wide range of successful ventures. Each project we undertake is a reflection of our expertise, collaborative spirit, and unwavering dedication to excellence.</p>
            </div>
        </div>
   
   
   
  </section>
</section>


     
    </div>
  );
}

export default Gallery;
